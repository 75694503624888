(function ($) {
    $(document).ready(function () {

        if ($('body').hasClass('blog') || $('body').hasClass('category')) {

            // on loaded
            if (
                ($('.blog__listing').children().length > 3 && $('body').width() <= 1100)
            || ($('.blog__listing').children().length > 4 && $('body').width() > 1100)
            ) {
                var grid = document.querySelector('.blog__listing');
                // instantiateMasonry(grid);
            }

            $('#more-posts').click(function () {
                var post_id = $('.blog__post:last-child').attr('data-post-id');
                var current_page = $(grid).attr('data-current-page');

                var temp = $(this).html();

                $(this).html('<i class="fas fa-redo-alt make-rotation"></i>');

                $.ajax({
                    url: ajaxurl,
                    type: "POST",
                    dataType: 'json',
                    data: {
                        'action': 'load_posts',
                        'post_id': post_id,
                        'current_page': current_page
                    },
                    success: function (response) {
                        grid.innerHTML = grid.innerHTML + response['htmlPosts'].stripSlashes();
                        // instantiateMasonry(grid);
                        current_page = parseInt(current_page) + 1;
                        $(grid).attr('data-current-page', current_page);

                        if (response['count'] < parseInt($(grid).attr('data-post-per-page'))) {
                            $(this).hide();
                        } else {
                            $(this).html(temp);
                        }
                    }.bind(this)
                });

            });
        }

    });
})(jQuery);
String.prototype.stripSlashes = function () {
    return this.replace(/\\(.)/mg, "$1");
}

function instantiateMasonry(grid) {
    new Masonry(grid, {
        // options...
        itemSelector: '.blog__post',
        // columnWidth: 200,
        fitWidth: true

    });
}